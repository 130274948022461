import React from 'react';
import { NoteContainer, NoteInput, NoteContent } from './StyledComponents'

export default function Note ({ coords, content, onUpdate, word, editingNote, toggleEditNote, showNoteArrow }) {
  return (<NoteContainer id="note-container" coords={coords} noteArrow={showNoteArrow} >
            { 
              editingNote ?
                <NoteInput
                  id="note-input" 
                  value={content || ''} 
                  onChange={(e) => onUpdate(word, e.target.value)} 
                  onClick={(e) => e.stopPropagation()}
                /> 
                :
                <NoteContent placeholder={content === undefined} onClick={toggleEditNote}>{<i>{content || 'type note here'}</i>}</NoteContent>
            }
            
          </NoteContainer>)
}