import React, { Component, Fragment } from 'react';

import { API } from "aws-amplify";
import queryString from 'query-string' 

import { UploadImage, Main, Sidebar, Hamburger, AppContainer, Controls, ColorPicker, Color, AddText, UploadInfo, Overlay, colors } from './StyledComponents'
import Editor from './Editor'
// import Pagination from './Pagination'
import WordList from './WordList'
import Note from './Note'

import { upload, paginate, totalPages, introText, chineseTextToArray } from './utils'
const { white, blue, green, yellow, red, commentGray } = colors

const removeSaved = { projectId: null, saved: false }
const initialState = {
  color: blue,
  colors: [ blue, green, yellow, red, commentGray ],
  wordList: {},
  notes: {},
  noteWord: '',
  noting: false,
  editingNote: false,
  uploaded: false,
  page: 0,
  editing: false,
  sidebar: false,
  saved: false,
  projectId: null
}

class App extends Component {

  text = []
  originalText = ''
  state = initialState
  saveState() {
    localStorage.state = JSON.stringify({ notes: this.state.notes, wordList: this.state.wordList, projectId: this.state.projectId })
    localStorage.text = JSON.stringify(this.text)
  }
  async clearState() {
    await this.setState({ sidebar: false })
    const confirmed = window.confirm("Are you sure you want to clear your text and words list?")
    if (confirmed) {
      this.text = []
      this.setState(initialState)  
    }
  }
  componentDidMount() {

    window.onbeforeunload =  (e) => { 
      this.saveState()
    }

    // check if we have a query
    const parsed = queryString.parse(window.location.search);
    if (parsed.id) {
      return this.load(parsed.id)
    }

    // check for versioning
    if (!localStorage.version || localStorage.version < 1.1) {
      localStorage.setItem('version', '1.1')
      return this.setState(initialState, () => {
        this.saveState()
      })
    } 

    // otherwise load our local project
    if (localStorage.text && JSON.parse(localStorage.text) && JSON.parse(localStorage.text).length > 0) {
      this.text = JSON.parse(localStorage.text)
    } else {
      this.handleText(introText + '\n\n More info: spongefile.ghost.io/rainbowedit/')
    }
    if (localStorage.state) {
      this.setState({ ...initialState, ...JSON.parse(localStorage.state)})
    }

  }
  save = async () => {
    try {
      this.setState({ saving: true })
      const result = await API.post("projects", "/projects", {
        body: {
         text: this.state.originalText || ' ',
         list: JSON.stringify(this.state.wordList),
         notes: JSON.stringify(this.state.notes)
        }
      })
      console.log(result)
      this.setState({ saved: true, projectId: result.projectId, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
      alert('Error saving your project, please try again')
    }
    
  }
  load = async (projectId) => {
    try {
      const result = await API.get("projects", `/projects/${projectId}`)
      console.log(result)
      await this.handleText(result.text)
      await this.setState({ ...initialState, saved: true, projectId: result.projectId, wordList: JSON.parse(result.list), notes: result.notes ? JSON.parse(result.notes) : {}})
    } catch (e) {
      console.log(e)
      alert('Error loading the project, please confirm the link and try again')
    }
  }
  changeColor = (color) => { 
    if (color !== commentGray) {
      this.lastColor = color
    }
    this.setState({ color })
  }
  addWord = (word, color, notesControl) => {
    if (this.state.wordList[word.toLowerCase()] && this.state.wordList[word.toLowerCase()] === this.state.color) {
      color = colors.white
    }
    if (this.state.sidebar && !notesControl) {
      return
    }
    this.setState({ ...removeSaved, wordList: { ...this.state.wordList, [word.toLowerCase()] : color}})
  }
  removeWord = (word) => {
    const wordList = this.state.wordList
    delete wordList[word.toLowerCase()]
    this.setState({ ...removeSaved, wordList })
  }
  onInputText = async (file) => {
    await this.setState({ uploaded: false })
    const text = await upload(file)
    this.setTextState(text)
  }
  async setTextState(text) {
    let textArray = await chineseTextToArray(text)
    const pages = totalPages(textArray)
    this.text = textArray
    this.setState({ ...removeSaved, uploaded: true, pages, page: 0, originalText: text })
  }
  handleText = (text) => {
    this.originalText = text
    this.setTextState(text)
  }
  updateWordListByColor = (color, words) => {
    const wordList = this.state.wordList
    for (const word in wordList) {
      if (wordList[word] === color) {
        delete wordList[word]
      }
    }
    words.forEach(word => {
      wordList[word] = color
    })
    this.setState({ wordList })
  }
  extractText = () => {
    const text = document.getElementById('input-text').value
    this.handleText(text)
  }
  toggleNoteMode = () => {
    this.setState({ noting: !this.state.noting })
  }
  stopEditingNote = () => {
    this.setState({ noteWord: '', editingNote: false })
  }
  showNote = (word, x, y) => {
    // lets move it to left / up a bit if on edges of screen
    const { innerWidth, innerHeight } = window
    this.showNoteArrow = true
    if (x > innerWidth - 300) {
      const overBy = 300 - (innerWidth - x)
      x = x - overBy
      this.showNoteArrow = false
    }
    if (y > innerHeight - 300) {
      const overBy = 450 - (innerHeight - y)
      y = y - overBy
      this.showNoteArrow = false
    }

    this.setState({ noteWord: word.toLowerCase(), noteCoords: [x, y] })
  }
  updateNote = (word, value) => {
    this.addWord(word, this.state.wordList[word] || white)
    this.setState({
      notes: {
        ...this.state.notes,
        [word.toLowerCase()]: value
      }
    })
  }
  deleteNote = (word) => {
    console.log('delete note', word )
    const notes = this.state.notes
    delete notes[word.toLowerCase()]
    this.setState({ notes, noteWord: '' })
  }
  toggleEditNote = () => {
    this.setState({ editingNote: !this.state.editingNote }, () => {
      if (this.state.editingNote) {
        document.getElementById('note-input').focus()
      }
    })
  }
  render() {
    const hamburgerState = { closed: !this.state.sidebar }
    const notesProps = {
      editingNote: this.state.editingNote,
      toggleEditNote: this.toggleEditNote,
      word: this.state.noteWord,
      onUpdate: this.updateNote,
      content: this.state.notes[this.state.noteWord],
      stopEditingNote: this.stopEditingNote,
      coords: this.state.noteCoords,
      showNoteArrow: this.showNoteArrow
    }
    return (
      <AppContainer>
        <Hamburger 
          { ...hamburgerState }
          onClick={() => this.setState({ sidebar: !this.state.sidebar, editing: false, /*color: this.state.color === commentGray ? this.state.colors[0] : this.state.color,*/ /*noting: false, noteWord: ''*/ })} 
        />
        <Main editing={this.state.editing} sidebar={this.state.sidebar}>
        <Editor 
          closeSidebar={() => this.setState({ sidebar: false })}
          sidebar={this.state.sidebar}
          text={paginate(this.text, this.state.page)}
          wordList={this.state.wordList}
          onClickWord={(word, x, y) => {
            if (this.state.noting) {
              this.showNote(word, x, y)
            } else {
              this.addWord(word, this.state.color)
            } 
          }
          }
          editing={this.state.editing}
          noting={this.state.noting}
          notes={this.state.notes}
          placeholder={this.state.originalText}
          noteWord={this.state.noteWord}
        />
        { /* <Pagination 
          pages={this.state.pages}
          page={this.state.page}
          changePage={(number) => this.setState({page: number})}
        /> */ } 
        <Controls editing={this.state.editing} sidebar={this.state.sidebar}>
          { !this.state.sidebar && <AddText onClick={() => {
            if (this.state.editing) {
              this.extractText()
            }
            this.setState({ editing: !this.state.editing })
          }
          }>
          <UploadImage editing={this.state.editing} />
          <UploadInfo editing={this.state.editing}>{!this.state.editing ? 'edit text' : 'edit color'}</UploadInfo>
          </AddText> }
          <ColorPicker sidebar={this.state.sidebar} editing={this.state.editing}>
            { this.state.colors
                // .filter(color => !(this.state.sidebar && color === commentGray))
                .map((color) => 
                <Color
                  id={color}
                  key={color} 
                  sidebar={this.state.sidebar} 
                  color={color} 
                  info={color === colors.white && this.state.sidebar} 
                  selected={color === this.state.color}
                  noting={color === commentGray} 
                  delete={color === commentGray && this.state.noteWord && this.state.notes[this.state.noteWord]}
                  onClick={() => { 
                    this.changeColor(color)
                    if (color !== commentGray) {
                      this.setState({ noting: false })
                    } else {
                      if (this.state.noteWord && this.state.notes[this.state.noteWord]) {
                        // delete the note
                        this.deleteNote(this.state.noteWord)
                      }
                      if (this.state.noting) {
                        this.setState({ noting: false, color: this.lastColor || this.state.colors[0] })
                      } else {
                        this.setState({ noting: true })
                      }
                    }
                  }}
                  />
            )}
          </ColorPicker>
        </Controls>
        </Main>
        <Sidebar sidebar={this.state.sidebar}>
          <WordList 
            saving={this.state.saving}
            save={() => this.save()}
            clearState={() => this.clearState()}
            saved={this.state.saved}
            projectId={this.state.projectId}
            wordList={this.state.wordList} 
            color={this.state.color}
            updateWordListByColor={this.updateWordListByColor}
            show={this.state.sidebar}
            notes={this.state.notes}
            addWord={this.addWord}
            removeWord={this.removeWord}
            updateNote={this.updateNote}
            deleteNote={this.deleteNote}
          />
        </Sidebar>
        { this.state.noteWord && 
          <Fragment>
            <Note 
              {...notesProps}
            />
            <Overlay onClick={() => this.stopEditingNote()}/>
          </Fragment>
        }
      </AppContainer>
    );
  }
}

export default App;
