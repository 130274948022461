import React, { Component, Fragment } from 'react';
import { EditorContainer, Word, TextArea, Overlay } from './StyledComponents'
import { breakIdentifier, tabIdentifier, regex } from './utils'

import Note from './Note'

class Editor extends Component {

  formatText = (text) => {

    let commenting = false
    let comment = ''
    const formatted = text.map((word, i) => {
      if (commenting) {
         if (word === '\\n') {
            commenting = false
            const finalComment = comment
            comment = ''
            return <Fragment><span style={{ color: 'gray' }}>{finalComment}</span><br key={i} /></Fragment>
         } else {
           comment += word
         }
         return
      }
      if (word === `\\"`) {
        return '"'
      }
      if (word === '\\n') {
        return <br key={i} />
      }
      else if (word === '\\t') {
        return <span style={{ opacity: 0 }}>'''''''</span>
      }
      else if (word === '//') {
        commenting = true
        comment += '//'
      }
      else if (!(/(.+?)([\s,.!"?;:)([\]]|\s'|'\s+)/g.test(word))) {
        return <Word 
                  key={i} 
                  id={`word-${i}`} 
                  underline={this.props.noting && this.props.notes[word.toLowerCase()]}
                  noteWord={this.props.noteWord.toLowerCase() === word.toLowerCase()}
                  color={this.props.wordList[word.toLowerCase()] || '#E3E3E3'} 
                  onClick={(e) => {
                    this.props.onClickWord(word, e.pageX, e.pageY)
                  }}>
                  {word}
                </Word>;
      } else {
        return word;
      }
   })
    return formatted
  }
  checkSidebar = () => {
    if (this.props.sidebar) {
      this.props.closeSidebar()
    }
  }
  checkTabs = (e) => {
    if (e.keyCode === 9) { // tab was pressed
        e.preventDefault()
        // get caret position/selection
        var val = e.target.value,
            start = e.target.selectionStart,
            end = e.target.selectionEnd;
        // set textarea value to: text before caret + tab + text after caret
        e.target.value = val.substring(0, start) + '\t' + val.substring(end);
        // put caret at right position again
        e.target.selectionStart = e.target.selectionEnd = start + 1;
        return false 
    }
  }

  render() {
    const { editing, placeholder } = this.props
    return (
      <EditorContainer editing={editing} onClick={() => this.checkSidebar() }>
        { editing ? <TextArea id='input-text' onKeyDown={this.checkTabs}>{placeholder}</TextArea> : this.formatText(this.props.text)}
      </EditorContainer>
    );
  }
}

export default Editor;
