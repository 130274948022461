const containsChinese = require('contains-chinese')
const wordsPerPage = 30000


export const upload = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = (event) => {
        	// split each page on demand into newlines
            resolve(event.target.result)
        };
        reader.readAsText(file);
    })
}

export const breakIdentifier = 'breakIdentifier'
export const tabIdentifier = 'tabIdentifier'

// export const regex = /'|[^" \.,:!?()\[\]]+[A-Za-z0-9а-яА-Я]|[^ ]|[^\"]|./gim;

const textToArray = (text) => {
var regexp = /\\t|\\n|\\"|[^" \.,:;«»!?&(){}<>\-\+\[\]\\/]+|\/{1,}|[A-Za-z0-9а-яА-Я\u00c0-\u00ff]+|[^ \"]|./gim; 
var newString = JSON.stringify(text); 
console.log(newString); 
var result = newString.match(regexp); 

// fixes some features of the Template string 
result.splice(0, 1); 
result.splice(result.length-1, 1); 


for(let i=0; i<result.length; i++){ 
  if(~result[i].indexOf("'") && result[i].length > 1){ 
  
    if(~result[i][0].indexOf("'") && ~result[i][result[i].length-1].indexOf("'")){
      result[i] = result[i].slice(1, -1);
      result.splice(i ,1 , "'", result[i], "'");
    }else if(~result[i][0].indexOf("'")){
      result[i] = result[i].slice(1); 
      result.splice(i ,1 , "'", result[i]);
    }else if(~result[i][result[i].length-1].indexOf("'")){
      console.log('я тут');
      result[i] = result[i].slice(0, -1); 
      result.splice(i ,1 , result[i], "'"); 
    }else{
      
    }
  } 
}
    return result
}
                                        
export const chineseTextToArray = async (text) => {
    return new Promise((resolve, reject) => {
        if (window.call_jieba_cut && containsChinese(text)) {
            window.call_jieba_cut(text, function (result) {
              resolve(result)
            });
        } else {
            console.log(textToArray(text))
            resolve(textToArray(text))
        }
    })
}


export const paginate = (text, page) => text.slice(page * wordsPerPage, (page + 1) * wordsPerPage)

export const totalPages = (text) => Math.ceil(text.length / wordsPerPage)

export const formatText = (wordList, color) => {
    let words = []
    for (const word in wordList) {
      if (wordList[word] === color) {
        words.push(word.toLowerCase())
      }
    }
    return words.filter(word => word.replace(' ', '')).join(' ')
  }

export const introText = `RainbowEdit is a tool for studying anything that's hard to read, whether it's a new language, poetry, or simply long words.

- "edit text" mode lets you edit the text itself
- "edit color" mode lets you edit the color of words
- the word lists you've generated are in the top right hand menu`