import React, { Component } from 'react';
import { WordListContainer, InfoContainer, WordListInput, Info, By, SectionTitle, colors } from './StyledComponents'
import { introText } from './utils'

import Link from './Link'
import ShareOptions from './ShareOptions'
import Notes from './Notes'

export const formatSpacedText = (text) => text.split(/\n/).map((text, i) => <p key={i} style={{ marginTop: 0 }}>{text}</p>)

class WordList extends Component {

  state = {
    value: null,
    copiedUrl: false
  }

  setCopied = async () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ copied: false })
      }, 2000)
    })
  }
  componentWillReceiveProps(nextProps) {
    const isNoteEditing = this.getHumanReadableColor(this.props.color) === 'commentGray'
    if (this.props.color !== nextProps.color && this.props.show) {
      !isNoteEditing && this.extractText(this.state.value)
      this.setState({ value: this.formatText(nextProps.wordList, nextProps.color) })
    }
    if (this.props.show && !nextProps.show) {
      !isNoteEditing && this.extractText(this.state.value)
    }
    if (!this.props.show && nextProps.show) {
      this.setState({ value: this.formatText(nextProps.wordList, nextProps.color) })
    }
  }

  componentWillMount() {
    this.setState({ value: this.getValue() })
  }

  extractText = () => {
    if (this.isDisabled()) {
      return
    }
    this.props.updateWordListByColor(this.props.color, document.getElementById('input-text').value.replace(/[\r\n]/g, '').split(/(.+?)([\s,.!"?;:)([\]]+)/g))
  }
  componentWillUnmount = () => {
    this.extractText()
  }
  formatText = (wordList, color) => {
    let words = []
    for (const word in wordList) {
      if (wordList[word] === color) {
        words.push(word.toLowerCase())
      }
    }
    return words.filter(word => word.replace(' ', '')).join(' ')
  }
  changeValue = (value) => {
    this.setState({ value: value.replace(/([,.!"?;:)([\]]+)/g, '').toLowerCase() })
  }
  isDisabled = () => this.props.color === colors.white
  getValue = () => {
    const { wordList, color } = this.props
    const content = this.formatText(wordList, color)
    if (this.state.value !== null && this.state.value !== content) {
      return this.state.value
    }
    return content
  }
  getHumanReadableColor = (color) => {
    let hreadableColor = null
    Object.keys(colors)
      .forEach(key => {
        if (colors[key] === color) {
          hreadableColor = key
        } 
      })
    return hreadableColor
  }
  render() {
    const { color, saved, projectId, save, clearState, wordList, notes, addWord, removeWord, updateNote, deleteNote } = this.props
    const content = this.getValue()
    const humanReadableColor = this.getHumanReadableColor(color)
    let titleContent = `List of words in ${humanReadableColor}:`
    let titleColor = color
    let titleTextColor = 'white'
    if (humanReadableColor === 'white') {
      titleColor = '#424E59'
      titleContent = 'About:' 
    }
    if (humanReadableColor === 'commentGray') {
      titleColor = colors.noteColor
      titleContent = 'List of words with notes'
      titleTextColor = colors.gray
    }
    return (
        <WordListContainer info={this.isDisabled()}>
          { saved && <Link projectId={projectId} copied={this.state.copied} setCopied={this.setCopied} /> }
          <ShareOptions 
            save={save}
            clearState={clearState}
            saving={this.props.saving}
          />
          <SectionTitle background={titleColor} color={titleTextColor}>{titleContent}</SectionTitle>
          { !this.isDisabled() ? 
             (humanReadableColor === 'commentGray' ? 
                <Notes wordList={wordList} notes={notes} addWord={addWord} removeWord={removeWord} updateNote={updateNote} deleteNote={deleteNote} /> 
              : <WordListInput id='input-text' color={color === colors.white ? colors.black : color} value={content} onChange={(e) => this.changeValue(e.target.value)}></WordListInput> )
            : 
            <InfoContainer>
              <Info>{formatSpacedText(introText)}</Info>
              <By>More info: <a style={{ color: 'rgb(223, 91, 96)'}} href='https://spongefile.ghost.io/rainbowedit/' target='_blank' >https://spongefile.ghost.io/rainbowedit/</a></By>
            </InfoContainer>
          }
        </WordListContainer>
    );
  }
}

export default WordList;
