import React, { Component } from 'react';
import { Options } from './StyledComponents'
import Option from './Option'

const about = () => {
  window.open(
    'https://spongefile.ghost.io/rainbowedit/',
    '_blank' // <- This is what makes it open in a new window.
  );
}

class ShareOptions extends Component {
  render() {
    const options = [ { onClick: this.props.save, boldText: 'save or share', extraText: 'a version of this text and lists', icon: 'shareicon.png'},
                      { onClick: this.props.clearState, boldText: 'clear all', extraText: 'text and lists', icon: 'clearicon.png'},
                      { onClick: about, boldText: 'about', extraText: 'this app', icon: 'abouticon.png', style: { marginTop: '8px' }}
                    ].map((options, i) => <Option {...options} animate={i === 0 && this.props.saving} />)
    return (
      <Options>
        {options}
      </Options>
    );
  }
}

export default ShareOptions;
