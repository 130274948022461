import React from 'react';
import { LinkContainer, LinkText, LinkInput, Copied } from './StyledComponents'

export default function Link ({ projectId, copied, setCopied }) {
  const copy = () => {
    var copyText = document.getElementById("share-link");
    copyText.select();
    document.execCommand("copy", false);
    setCopied()
  }
  const storeCopy = (event) => {
  	event.preventDefault();
  	if (event.clipboardData) {
  	  event.clipboardData.setData("text/plain", `https://rainbowedit.com/?id=${projectId}`);
  	}
  }
  return (<LinkContainer onClick={copy} onCopy={storeCopy}>
      			<LinkText>Link to this version:</LinkText>
      			<LinkInput copied={copied} id="share-link" disabled="disabled" defaultValue={`https://rainbowedit.com/?id=${projectId}`}/>
            { copied && <Copied>Copied</Copied> }
          </LinkContainer>)
}