import React, { Component } from 'react'

import { colors, NotesListContainer, NotesListNewWord, NotesListDelete, WordContainer, NotesListWord, NotesListInput, NotesListNote } from './StyledComponents'
 

class Notes extends Component {
  state = {
    wordInput: '',
    noteInput: null,
    editingWord: null,
    deletingWord: null
  }
    setEditingWord = (word, note) => {
      this.setState({ editingWord: word, noteInput: note }, () => {
        const element = document.getElementsByClassName(`notes-${word}`)[0]
        if (element) {
          element.focus()
        }
      })
    }
    showDeleteIcon = (e, word) => {
      e.stopPropagation()
      this.setState({ deletingWord: word})
    }
    hideDeleteIcon = () => {
      this.setState({ deletingWord: null })
    }
    addWord = () => {
      this.props.addWord(this.state.wordInput, this.props.wordList[this.state.wordInput] || colors.white, true)
      this.props.updateNote(this.state.wordInput, '')
      this.setState({ wordInput: '' })
    }
    deleteWord = (word) => {
      this.props.removeWord(word)
    }
    updateNote = (word) => {
      this.props.updateNote(word, this.state.noteInput)
      this.setState({ noteInput: null })
    }
    deleteNote = (word) => {
      // this.deleteWord(word)
      this.props.deleteNote(word)
    }
    render() {
      const { wordList, notes } = this.props
      const words = []
      for (const word in wordList) {
        words.push({ word: word.toLowerCase(), color: wordList[word], note: notes[word] })
      }
      const wordElements = words.filter(config => config.word.trim() && config.note !== undefined).reverse().map((config) => {
        const isEditing =  this.state.editingWord === config.word 
        const isDeleting = this.state.deletingWord === config.word
        if (isDeleting) {
          return (<WordContainer onClick={() => this.deleteNote(config.word)}><NotesListDelete />delete</WordContainer>)
        }
        let inputValue = (isEditing && this.state.noteInput) || config.note || ''
        if (this.state.noteInput === '') {
          inputValue = ''
        }
        return (<WordContainer editing={isEditing}>
                  <NotesListWord color={config.color === colors.white ? colors.gray : config.color} onClick={(e) => this.showDeleteIcon(e, config.word)}>
                    {config.word}:
                  </NotesListWord>
                  { !config.note || isEditing ? 
                      <NotesListInput
                        className={`notes-${config.word}`}
                        id="notes-list-input"
                        onFocus={() => this.setEditingWord(config.word, config.note)}
                        value={inputValue} 
                        placeholder="add comment" 
                        /* onKeyDown={e => e.key === 'Enter' && this.updateNote(config.word)} */
                        onBlur={() => this.updateNote(config.word)} 
                        onChange={(e) => this.setState({ noteInput: e.target.value })}
                      /> 
                      : <NotesListNote onClick={() => this.setEditingWord(config.word, config.note)}>{config.note}{/* isEditing && <NotesListDelete onClick={() => this.deleteNote(config.word)}/>*/}</NotesListNote> }
                </WordContainer>)
      })
      return (
        <NotesListContainer onClick={() => this.hideDeleteIcon()}>  
          <NotesListNewWord placeholder="add word here, press return" value={this.state.wordInput} onKeyDown={e => e.key === 'Enter' && this.addWord()} onChange={(e) => this.setState({ wordInput: e.target.value })}/>
          {wordElements}
        </NotesListContainer>
      );
    }
}

export default Notes;
