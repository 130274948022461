import styled, { css, keyframes } from 'styled-components'

export const colors = {
	white: '#E3E3E3',
	blue: '#5EABE3',
	green: '#98C892',
	yellow: '#FBAF4F',
	red: '#DF5B60',
	gray: '#343D46',
	noteColor: '#FFDE52',
	commentGray: '#424E59'
}

const animate = css`
	-webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
`

export const AppContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const Main = styled.div`
	width: ${props => props.sidebar ? 50 : 100}%;
	height: 100%;
	background: ${props => props.editing ? 'white' : '#343D46}'};
	color: #E3E3E3;
	position: relative;
	${animate}
    @media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const Sidebar = styled.div`
	width: 50%;
	height: 100%;
	top: 0px;
	position: fixed;
	background: ${colors.white};
	right: ${props => props.sidebar ? 0 : 'calc(0px - 50%)'};
	${animate}
    ${props => props.sidebar && css`
    	@media only screen and (max-width: 600px) {
			width: 100%;
			right: 0;
			top: 0px;
			position: absolute;
		}
    `}
`

export const AddText = styled.div`
	width: 20%;
	height: 100%;
	display: flex;
	cursor: pointer;
  	justify-content: center;
  	flex-direction: row;
  	padding-top: 15px;
    @media only screen and (max-width: 600px) {
		height: 100px;
		width: 100%;
	}
	user-select: none;
`

export const UploadImage = styled.div`
	width: 22px;
	height: 22px;
	background: ${props => props.editing ? 'url(editcolor.png)' : 'url(edittext.png)'};
	background-size: 100%;
	background-repeat: no-repeat;
	margin: 25px 10px;
`

export const UploadInfo = styled.p`
	font-size: 22px;
	font-weight: bold;
	width: 100px;
	color: ${props => props.editing ? colors.gray : colors.white};
`

export const Controls = styled.div`
	width: calc(100% - 20px);
	height: 100px;
	padding: 10px;
	background: ${props => props.editing ? 'white' : '#343D46'};
	border-top: 1px solid #E3E3E3;
	display: flex;
	position: absolute;
	bottom: 0;
	z-index: 9998;
	@media only screen and (max-width: 600px) {
		height: 150px;
		flex-direction: column-reverse;
	}
	${props => props.sidebar && css`
		@media only screen and (max-width: 600px) {
			height: 100px;
		}
	`}
`

export const Upload = styled.div`
	width: 20%;
	height: 100%;
	display: flex;
	cursor: pointer;
  	justify-content: center;
  	flex-direction: column;
  	text-align: center;
    @media only screen and (max-width: 600px) {
		height: 100px;
		width: 100%;
	}
`

export const ColorPicker = styled.div`
	width: ${props => props.sidebar ? '100%' : '80%'};
	height: 100%;
	display: flex;
	justify-content: ${props => props.sidebar ? 'center' : 'flex-end'};
	align-items: center;
	${props => props.editing && css`
		opacity: 0.5;
		pointer-events: none;
	`}
	@media only screen and (max-width: 600px) {
		height: 100px;
		width: 100%;
		justify-content: center;
	}
`

export const Color = styled.div`
	background: ${props => props.color};
	border: ${props => props.selected ? '6px solid white' : 0}
	width: 60px;
	height: 60px;
	min-width: 60px;
	border-radius: 100%;
	margin-right: ${props => props.selected ? 'calc(5% - 6px)' : 'calc(5%)'};
	margin-left: ${props => props.selected ? '-6px' : '0'}
	cursor: pointer;
	${props => props.noting && css`
		background-image: url('commentsicon.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 60%;
	`}
	${props => props.delete && css`
		background-image: url('commentsx.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 60%;
	`}
	${props => props.info && css`
		background: url('info.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 90%;
	`}
	}
	${props => props.sidebar && css`
		@media only screen and (max-width: 800px) {
			height: 40px;
			width: 40px;
			min-width: 40px;
		}
	`}
	@media only screen and (max-width: 400px) {
		height: 40px;
		width: 40px;
		min-width: 40px;
	}
`

export const EditorContainer = styled.div`
	width: calc(100% - 70px);
	overflow: scroll;
	height: calc(100% - 160px);
	padding: 35px;
	font-size: 24px;
	margin: 0;
	@media only screen and (max-width: 600px) {
		font-size: 18px;
		height: calc(100% - 210px);
	}
`

export const TextArea = styled.textarea`
	width: 100%;
	height: 100%;
	flex-grow: 3;
	font-size: 24px;
	background: 0;
	color: ${colors.gray};
	outline: 0;
	border: 0;
	margin: -2px;
	resize: none;
	font-family: "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
`

export const Word = styled.span`
	color: ${props => props.color};
	${props => props.underline && css`
		text-decoration: underline;
  		text-decoration-color: ${colors.noteColor};
	`}
	${props => props.bold && css`
		font-weight: bolder;
	`}
	${props => props.noteWord && css`
		color: ${colors.noteColor};
	`}
	cursor: pointer;
`

export const PaginationContainer = styled.div``

export const PageLeft = styled.div`
	position: fixed; 
	left: 0;
	top: calc(50% - 50px);
	width: 30px;
	height: 100px;
	background: #E3E3E3;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	&:after {
    	content: '<';
    	font-size: 38px;
    	position: absolute;
    	left: 4px;
    	top: calc(100% / 2 - 24px);
    	color: #343D46;
    	font-weight: bold;
    }	
`
export const PageRight = styled.div`
	position: fixed; 
	right: 0;
	top: calc(50% - 50px);
	width: 30px;
	height: 100px;
	background: #E3E3E3;
	border-radius: 5px 0 0 5px;
	cursor: pointer;
	&:after {
    	content: '>';
    	font-size: 38px;
    	position: absolute;
    	right: 4px;;
    	top: calc(100% / 2 - 24px);
    	color: #343D46;
    	font-weight: bold;
    }
`

export const PasteInputContainer = styled.div`
	width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background: ${colors.gray};
    border-radius: 20px;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    border: 2px solid white;
`

export const Instruction = styled.h1`
	width: 100%;
	color: ${colors.gray};
`


export const Buttons = styled.div`
	display: flex;
	flex-grow: 1;
	width: 100%;
	justify-content: space-around;
	margin-top: 50px;
`

export const Button = styled.button`
	text-align: center;
	width: 200px;
	height: 60px;
	background: ${props => {
		if (props.cancel) {
			return colors.red
		} else {
			return colors.green
		}
	}};
	cursor: pointer;
	color: white;
	border-radius: 5px;
	font-size: 20px;
	&:focus{
    	outline: 0;
  	}
  	font-weight: bolder;
  	margin: 5px;
  	border: none;
  	outline: 0;
`

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	background: 0;
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;
`

export const Hamburger = styled.div`
	width: 32px;
	height: 32px;
	background: ${props => {
		if (props.closed) {
			return 'url(hamburger-closed.png)'
		} 
		return 'url(hamburger-open.png)'
	}};
	fill: #E3E3E3;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 32px;
	position: fixed;
	right: 10px;
	top: 10px;
	z-index: 9999;
	cursor: pointer;
`

export const WordListContainer = styled.div`
	width: 100%;
	height: 100%;
	background: white;
	@media only screen and (max-width: 600px) {
		height: calc(100% - 100px);
	}
`

export const WordListInput = styled.textarea`
	width: calc(100% - 80px);
	height: calc(100% - 370px);
	background: 0;
	margin: 20px;
	resize: none;
	font-size: 24px;
	padding: 20px;
	color: ${props => props.color};
	outline: 0;
	border: none;
	&:focus{
    	outline: 0;
  	}
`

export const InfoContainer = styled.div`
	overflow-y: scroll;
	height: calc(100% - 295px);
	width: 100%;
`

export const Info = styled.div`
	width: auto;
	height: auto;
	background: 0;
	margin: 0px 20px;
	font-size: 24px;
	padding: 20px 20px 0px 20px;
	color: ${colors.gray};
	outline: none;
	border: none;
	&:focus{
    	outline: 0;
  	}
`

export const By = styled.div`
	background: 0;
	font-size: 24px;
	color: ${colors.gray};
	margin: 20px;
    padding: 20px;
`

export const Options = styled.div`
	height: 210px;
	display: flex;
	flex-direction: column;
	margin: 0px 20px 0px 20px;
	padding: 20px 20px 20px 20px;
`

const flash = keyframes`
  0% {
  	opacity: 1
  }
  50% {
    opacity: 0;
  }
  100% {
  	opacity: 1
  }
`;

export const SingleOption = styled.div`
	width: calc(100% - 80px);
	height: 100px;
	display: flex;
	vertical-align: center;
	align-items: center;
	font-size: 22px;
	cursor: pointer;
	${props => props.animate && css`
		animation: ${flash} 1s linear infinite;
	`}
	
`
export const OptionText = styled.p`
	height: auto;
	margin: 0;
	padding: 0;
	user-select: none;
`

export const OptionIcon = styled.div`
	width: 48px;
	height: 48px;
	background: ${props => {
		return `url(${props.url})`
	}};
	min-width: 48px;
	fill: #E3E3E3;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 48px;
	margin: 0 15px 0 0;
	padding: 0;
`

export const LinkContainer = styled.div`
	height: 100px;
	background: ${colors.green};
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
`
export const LinkText = styled.p`
	color: white;
	font-size: 22px;
	font-weight: bold;
	margin: 15px;
`

export const LinkInput = styled.input`
	margin: -7px 10px;
	width: calc(100% - 40px);
	padding: 10px;
	font-size: 16px;
	height: 20px;
	outline: 0;
	border: 0;
	cursor: pointer;
	${props => props.copied && css`
		animation: ${flash} 1s linear;
	`}
`

export const Copied = styled.p`
	padding: 5px;
    background: ${colors.yellow};
    color: white;
    position: absolute;
    bottom: -7px;
    right: 9px;
    border-radius: 5px;
`

export const SectionTitle = styled.div`
	padding: 10px 10px 10px 20px;
	background: ${props => props.background};
	color: ${props => props.color};
	font-size: 20px;
	font-weight: bolder;
`

export const NoteContainer = styled.div`
	position: fixed;
	left: ${props => props.coords[0] - 40}px;
	top: ${props => props.coords[1] + 22}px;
	width: 300px;
	height: 200px;
	resize: vertical
	z-index: 2;
	background: ${colors.noteColor};
	box-sizing: border-box;
	padding: 5px;
	border: 1px solid #343D46;
	${props => props.noteArrow && css`
		&::after {
    	content: "";
		position: absolute;
		bottom: 100%;
		left: 25px;
		width: 0;
		height: 0;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-top: 15px solid ${colors.noteColor};
		transform: rotate(180deg);
		}
		&&:before {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 25px;
			width: 0;
			height: 0;
			border-left: 16px solid transparent;
			border-right: 16px solid transparent;
			border-top: 16px solid ${colors.noteColor};
			transform: rotate(180deg);
		}
		&&:before {
			border-right: 15px solid hsla(0,0%,0%,.1);
			bottom: -18px;
			left: 20px;
			z-index: 1;
			transform: rotate(180deg);
		}
		&&:before {
			pointer-events:none;
			border-right: 16px solid transparent;
			border-bottom: 16px solid transparent;
			border-top: 16px solid #343D46;
			bottom: 100%;
	    	left: 24px;
			z-index: 0;
			transform: rotate(180deg);
		}
	`}
`
export const NoteInput = styled.textarea`
	outline: 0;
	border: none;
	width: 100%;
	height: calc(200px - 10px);
	padding: 5px;
	box-sizing: border-box;
	background: 0;
	font-size: 14px;
	min-height: 20px;
	font-style: italic;
`

export const NoteContent = styled.div`
	outline: 0;
	border: none;
	width: 100%;
	height: 100vh;
	padding: 5px;
	box-sizing: border-box;
	font-size: 14px;
	min-height: 20px;
	${props => props.placeholder && css`
		color: #A69853;
	`}
`

export const NotesListContainer = styled.div`
	overflow-y: scroll;
	height: calc(100% - 295px);
`
export const NotesListNewWord = styled.input`
	height: 25px;
    margin: 10px;
    width: calc(100% - 35px);
    padding: 5px;
    font-size: 16px;
    font-style: italic;
`
export const WordContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 10px 20px;
	padding: 5px;
	cursor: pointer;
	font-size: 20px;
	${props => props.editing && css`
		cursor: auto;
	`}
`
export const NotesListWord = styled.p`
	color: ${props => props.color};
	font-weight: bolder;
	position: relative;
	margin: 0 20px 0 0;
`
export const NotesListInput = styled.input`
	height: 100%;
	font-size: 20px;
	position: relative;
	border: 0;
    outline: 0;
    width: 100%;
    font-style: italic;
`
export const NotesListNote = styled.p`
	position: relative;
	margin: 0px;
`
export const NotesListDelete = styled.div`
	width: 24px;
    height: 24px;
    background-image: url(deletesmallicon.png);
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-right: 10px;
    background-position: center;
`